export const ROUTE_NAMES = {
  DEFAULT: Symbol('DEFAULT'),
  DISCLAIMER: Symbol('DISCLAIMER'),
  //
  ACTIVATE: Symbol('ACTIVATE'),
  INVITE_TRANSACTIONS: Symbol('INVITE_TRANSACTIONS'),
  INVITES_MANAGE: Symbol('INVITES_MANAGE'),
  //
  LOGIN: Symbol('LOGIN'),
  LOGIN_OTHER: Symbol('OTHER'),
  REDOWNLOAD: Symbol('REDOWNLOAD'),
  RETICKET: Symbol('RETICKET'),
  PERMISSIONS: Symbol('PERMISSIONS'),
  //
  ACCEPT_TRANSFER: Symbol('ACCEPT_TRANSFER'),
  CANCEL_TRANSFER: Symbol('CANCEL_TRANSFER'),
  POINTS: Symbol('POINTS'),
  POINT: Symbol('POINT'),
  SIGIL_GENERATOR: Symbol('SIGIL_GENERATOR'),
  CREATE_GALAXY: Symbol('CREATE_GALAXY'),
  SENATE: Symbol('SENATE'),
  DOCUMENTS: Symbol('DOCUMENTS'),
  UPGRADES: Symbol('UPGRADES'),
  ISSUE_CHILD: Symbol('ISSUE_CHILD'),
  PARTY_SET_POOL_SIZE: Symbol('PARTY_SET_POOL_SIZE'),
  INVITE_COHORT: Symbol('INVITE_COHORT'),
  STAR_RELEASE: Symbol('STAR_RELEASE'),
  ACCEPT_LOCKUP: Symbol('ACCEPT_LOCKUP'),
  URBIT_OS: Symbol('URBIT_OS'),
  URBIT_ID: Symbol('URBIT_ID'),
  RESIDENTS: Symbol('RESIDENTS'),
  ADOPT: Symbol('ADOPT'),
  BITCOIN: Symbol('BITCOIN'),
  BITCOIN_XPUB: Symbol('BITCOIN_XPUB'),
  BITCOIN_SIGN_TRANSACTION: Symbol('BITCOIN_SIGN_TRANSACTION'),
};
